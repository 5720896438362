export enum ChatSearchParams {
  DialogId = 'dialogId',
  ConversationId = 'conversationId',
}

export const EmptyConversationId = 'empty';

export const sampleQuestions = [
  "2024年奖金政策是什么？",
  "天龙八步是什么？",
  "无产品，有价签可以算作门店有分销吗?" ,
  "绿箭OTC是不是大卖场必分销SKU？",
  "顾客为什么选择玛氏口香糖？",
  "绿箭口香糖的特点有什么？",
  "可可脂和代可可脂的区别",
  "2024年的新品清单",
  "AI识别结果不准确怎么办？",
  "咀嚼口香糖有哪些益处",
  "易下单订单下错，销售无法当场修正订单, 怎么处理？",
  "玛氏常用缩写有哪些？",
  "酸条的箱规是多少",
  "士力架35克的保质期是多长时间"
]


